/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { db2, db, ref, onValue, off } from "../firebase"; // Import the necessary functions from your firebaseConfig
import EventCard from "../elements/EventCard"; // Import the card component
import EventModal from "../elements/EventModal"; // Import the modal component
import AddEventModal from "../elements/AddEventModal"; // Import the add event modal component
import "../css/EventPage.css"; // Import styles
import DatePicker from "react-datepicker";
import Select from 'react-select';

const EventPage = ({ user, userRole }) => {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const [filters, setFilters] = useState({
    companyName: "",
    dateBegin: "",
    dateEnd: "",
  });

  useEffect(() => {
    const eventsRef = ref(db2, "events");
    const companiesRef = ref(db2, "companies");
    const employeesRef = ref(db, "users");

    const fetchEventsAndListen = (setData) => {
      const listener = onValue(eventsRef, (snapshot) => {
        if (snapshot.exists()) {
          const eventList = Object.entries(snapshot.val()).map(
            ([key, value]) => ({ id: key, ...value })
          );
          setData(eventList);
        } else {
          setData([]);
        }
      });
      return listener;
    };

    const fetchEmployeesAndlisten = (setData) => {
      const listener = onValue(employeesRef, (snapshot) => {
        if (snapshot.exists()) {
          const employeeList = Object.entries(snapshot.val()).map(
            ([key, value]) => ({ id: key, ...value })
          );
          setEmployees(employeeList);
        } else {
          setData([]);
        }
      });
      return listener;
    };

    const fetchCompaniesAndListen = (setData) => {
      const listener = onValue(companiesRef, (snapshot) => {
        if (snapshot.exists()) {
          const companyList = Object.values(snapshot.val()).filter(
            (company) => company && company.ime
          );
          setData(companyList);
        } else {
          setData([]);
        }
      });
      return listener;
    };

    const eventListener = fetchEventsAndListen(setEvents);
    const companyListener = fetchCompaniesAndListen(setCompanies);
    const employeeListener = fetchEmployeesAndlisten(setEmployees);
    console.log(JSON.stringify(employees));

    return () => {
      off(eventsRef, "value", eventListener);
      off(companiesRef, "value", companyListener);
      off(employeesRef, "value", employeeListener);
    };
  }, []);

  const handleDateChange = (date, field) => {
    setFilters((prevState) => ({
      ...prevState,
      [field]: date,
    }));
  };

  useEffect(() => {
    applyFilters(events);
  }, [filters, showInactive, showAddEventModal, events, selectedEmployee]);

  const applyFilters = (eventsList) => {
    let filtered = eventsList;

    // Apply company name filter
    if (filters.companyName) {
      filtered = filtered.filter((event) =>
        event.companyName
          .toLowerCase()
          .includes(filters.companyName.toLowerCase())
      );
    }

    // Apply date range filter
    if (filters.dateBegin) {
      const beginDate = new Date(filters.dateBegin);
      filtered = filtered.filter((event) => new Date(event.date) >= beginDate);
    }
    if (filters.dateEnd) {
      const endDate = new Date(filters.dateEnd);
      filtered = filtered.filter((event) => new Date(event.date) <= endDate);
    }

    // Filter by active/inactive status
    if (showInactive) {
      filtered = filtered.filter((event) => !event.isActive);
    }

    // Filter by user role
    if (userRole !== "admin") {
      filtered = filtered.filter(
        (event) => event.createdBy === user.displayName
      );
    }

        // Filter by user name
        if (selectedEmployee) {
          filtered = filtered.filter(
            (event) => event.createdBy === selectedEmployee.value
          );
        }

    // Sort events by date from earliest to latest
    filtered.sort((a, b) => new Date(a.date) - new Date(b.date));

    setFilteredEvents(filtered);
  };

  const handleDetailsClick = (event) => {
    setModalData(event);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalData(null);
  };

  const handleAddEventModalClose = () => {
    setShowAddEventModal(false);
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleAddButtonClick = () => {
    setShowAddEventModal(true);
  };

  const handleClearButtonClick = () => {
    setFilters({
      companyName: "",
      dateBegin: "",
      dateEnd: "",
    });
    setSelectedEmployee(null);
  };

  // Filter events into sections
  const filteredEventsSections = {
    pastDue: filteredEvents.filter(
      (event) => new Date(event.date) < new Date()
    ),
    next7Days: filteredEvents.filter((event) => {
      const eventDate = new Date(event.date);
      const today = new Date();
      const sevenDaysLater = new Date(today);
      sevenDaysLater.setDate(today.getDate() + 7); // Corrected date calculation
      return eventDate >= today && eventDate <= sevenDaysLater;
    }),
    upcoming: filteredEvents.filter(
      (event) => new Date(event.date) > new Date()
    ),
  };

  return (
    <div className="event-page-container">
      <div className="filters-container">
      {userRole==="admin" &&(
          <>
          <Select
  id="industryFilter"
  options={[
    { value: null, label: 'Изберете одговорен' },
    ...employees.map(employee => ({ value: employee.name, label: employee.name }))
  ]}
  value={selectedEmployee}
  onChange={setSelectedEmployee}
  placeholder="Изберете одговорен"
  className="filter-dropdown"
  isClearable
/>
          </>
        )}
        <input
          type="text"
          name="companyName"
          placeholder="Назив на фирма...."
          value={filters.companyName}
          onChange={handleFilterChange}
          className="filter-input"
        />
          <DatePicker
          selected={filters.dateBegin}
          name="dateBegin"
          onChange={(date) => handleDateChange(date, "dateBegin")}
          dateFormat="dd-MM-yyyy"
          className="filter-input"
          placeholderText="датум од"
        />
        <DatePicker
          selected={filters.dateEnd}
          name="dateEndd"
          onChange={(date) => handleDateChange(date, "dateEnd")}
          dateFormat="dd-MM-yyyy"
          className="filter-input"
          placeholderText="датум до"
        />
        <button onClick={handleClearButtonClick} className="add-button">
          Одстрани филтер
        </button>
        <button onClick={handleAddButtonClick} className="add-button">
          Додај Активност
        </button>
        <label className="filter-checkbox-label">
          <input
            type="checkbox"
            checked={showInactive}
            onChange={() => setShowInactive((prev) => !prev)}
            className="filter-checkbox"
          />
          Прикажи неактивни Активности
        </label>
      </div>
      <div className="cards-container">
        <table className="event-table">
          <tbody>
            <tr>
              <td className="column">
                <h2>Активности со изминат датум</h2>
                {filteredEventsSections.pastDue.map((event) => (
                  <EventCard
                    key={event.id}
                    event={event}
                    userRole={userRole}
                    onDetailsClick={() => handleDetailsClick(event)}
                    backgroundColor={
                      event.createdBy === user.displayName ? "#fff" : "#f0f0f0"
                    }
                  />
                ))}
              </td>
              <td className="column">
                <h2>предвидени во наредните 7 дена</h2>
                {filteredEventsSections.next7Days.map((event) => (
                  <EventCard
                    key={event.id}
                    event={event}
                    userRole={userRole}
                    onDetailsClick={() => handleDetailsClick(event)}
                    backgroundColor={
                      event.createdBy === user.displayName ? "#fff" : "#f0f0f0"
                    }
                  />
                ))}
              </td>
              <td className="column upcoming-events">
                <h2>Активности предвидени во иднина</h2>
                <div className="upcoming-events-grid">
                  {filteredEventsSections.upcoming
                    .filter(
                      (event) =>
                        !filteredEventsSections.next7Days.includes(event)
                    )
                    .map((event) => (
                      <EventCard
                        key={event.id}
                        event={event}
                        userRole={userRole}
                        onDetailsClick={() => handleDetailsClick(event)}
                        backgroundColor={
                          event.createdBy === user.displayName
                            ? "#fff"
                            : "#f0f0f0"
                        }
                      />
                    ))}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {showModal && <EventModal data={modalData} onClose={handleModalClose} />}
      {showAddEventModal && (
        <AddEventModal
          companies={companies}
          onClose={handleAddEventModalClose}
          user={user}
        />
      )}
    </div>
  );
};

export default EventPage;
